<template>
    <section class="cotizaciones-main container-fluid">
        <navbar-admin titulo="Cotizaciones"  />
        <tabs :tabs="tabsVerProveedores" />
        <router-view />
    </section>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data(){
        return{
            tabsVerProveedores: [
                {
                    titulo:'Vigentes',
                    ruta:"cotizaciones.vigentes",
                },
                {
                    titulo:'Enviadas',
                    ruta:"cotizaciones.enviadas",
                },
                {
                    titulo:'Cotizaciones clientes',
                    ruta:"cotizaciones.clientes",
                },
            ],
        }
    },
    watch: {
        '$route.name'(val) {
            this.replaceBreadcumb({
                position: 1,
                breadcumb: val
            })
        }
    },
    created () {
        this.getBreadcumbs(['cotizaciones.main',this.$route.name]);
    },
    methods:{
        ...mapActions({
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            replaceBreadcumb: 'navigation/breadcumbs/replaceBreadcumb'
        })
    }
}
</script>

<style lang="scss" scoped>
.cobro-cartera-main{
    background: white;
}
</style>